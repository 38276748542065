export const GET_PLACES = 'GET_PLACES';
export const GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS';
export const GET_PLACES_ERROR = 'GET_PLACES_ERROR';

export const GET_PLACE = 'GET_PLACE';
export const GET_PLACE_SUCCESS = 'GET_PLACE_SUCCESS';
export const GET_PLACE_ERROR = 'GET_PLACE_ERROR';

export const ADD_PLACE = 'ADD_PLACE';
export const ADD_PLACE_SUCCESS = 'ADD_PLACE_SUCCESS';
export const ADD_PLACE_ERROR = 'ADD_PLACE_ERROR';

export const EDIT_PLACE = 'EDIT_PLACE';
export const EDIT_PLACE_SUCCESS = 'EDIT_PLACE_SUCCESS';
export const EDIT_PLACE_ERROR = 'EDIT_PLACE_ERROR';

export const DELETE_PLACE = 'DELETE_PLACE';
export const DELETE_PLACE_SUCCESS = 'DELETE_PLACE_SUCCESS';
export const DELETE_PLACE_ERROR = 'DELETE_PLACE_ERROR';

export const CLEAR_PLACE_STATE = 'CLEAR_PLACE_STATE';
