import {
  GET_BOARDS,
  GET_BOARDS_SUCCESS,
  GET_BOARDS_ERROR,
  GET_BOARD,
  GET_BOARD_SUCCESS,
  GET_BOARD_ERROR,
  ADD_BOARD,
  ADD_BOARD_SUCCESS,
  ADD_BOARD_ERROR,
  EDIT_BOARD,
  EDIT_BOARD_SUCCESS,
  EDIT_BOARD_ERROR,
  DELETE_BOARD,
  DELETE_BOARD_SUCCESS,
  DELETE_BOARD_ERROR,
  CLEAR_BOARD_STATE,
} from './types';

export const getters = {
  boards: (state) => state.boards,
  boardsStatus: (state) => state.boardsStatus,
  board: (state) => state.board,
  boardStatus: (state) => state.boardStatus,
  addBoardStatus: (state) => state.addBoardStatus,
  editBoardStatus: (state) => state.editBoardStatus,
  deleteBoardStatus: (state) => state.deleteBoardStatus,
};

export const mutations = {
  [GET_BOARDS]: (state) => {
    state.boardsStatus = 'loading';
  },
  [GET_BOARDS_SUCCESS]: (state, payload) => {
    state.boards = payload.data;
    state.boardsStatus = 'success';
  },
  [GET_BOARDS_ERROR]: (state, e) => {
    state.boardsStatus = e;
  },
  [GET_BOARD]: (state) => {
    state.boardStatus = 'loading';
  },
  [GET_BOARD_SUCCESS]: (state, payload) => {
    state.board = payload.data;
    state.boardStatus = 'success';
  },
  [GET_BOARD_ERROR]: (state, e) => {
    state.boardStatus = e;
  },
  [ADD_BOARD]: (state) => {
    state.addBoardStatus = 'loading';
  },
  [ADD_BOARD_SUCCESS]: (state) => {
    state.addBoardStatus = 'success';
  },
  [ADD_BOARD_ERROR]: (state, e) => {
    state.addBoardStatus = e;
  },
  [EDIT_BOARD]: (state) => {
    state.editBoardStatus = 'loading';
  },
  [EDIT_BOARD_SUCCESS]: (state) => {
    state.editBoardStatus = 'success';
  },
  [EDIT_BOARD_ERROR]: (state, e) => {
    state.editBoardStatus = e;
  },
  [DELETE_BOARD]: (state) => {
    state.deleteBoardStatus = 'loading';
  },
  [DELETE_BOARD_SUCCESS]: (state) => {
    state.deleteBoardStatus = 'success';
  },
  [DELETE_BOARD_ERROR]: (state, e) => {
    state.deleteBoardStatus = e;
  },
  [CLEAR_BOARD_STATE]: (state) => {
    state.board = {};
    state.boardStatus = '';
    state.addBoardStatus = '';
    state.editBoardStatus = '';
    state.deleteBoardStatus = '';
  },
};

export const state = {
  boards: [],
  boardsStatus: '',
  board: {},
  boardStatus: '',
  addBoardStatus: '',
  editBoardStatus: '',
  deleteBoardStatus: '',
};
