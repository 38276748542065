import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  // PASSWORD_RECOVERY_REQUEST,
  // PASSWORD_RECOVERY_SUCCESS,
  // PASSWORD_RECOVERY_ERROR,
  LOGOUT,
} from './types';

export const getters = {
  isAuthenticated: (state) => !!state.authToken,
  authStatus: (state) => state.authStatus,
  signupStatus: (state) => state.signupStatus,
};

export const mutations = {
  [LOGIN_REQUEST]: (state) => {
    state.authStatus.type = 'loading';
    state.authStatus.type = '';
    state.authStatus.error = '';
  },
  [LOGIN_SUCCESS]: (state, action) => {
    state.authStatus.type = 'success';
    state.authToken = action.data.token;
  },
  [LOGIN_ERROR]: (state, e) => {
    state.authStatus.type = 'error';
    state.authStatus.error = e.response;
  },
  [SIGNUP_REQUEST]: (state) => {
    state.signupStatus.type = 'loading';
  },
  [SIGNUP_SUCCESS]: (state) => {
    state.signupStatus.type = 'success';
  },
  [SIGNUP_ERROR]: (state, e) => {
    state.signupStatus.type = 'error';
    state.signupStatus.error = e.response;
  },
  [LOGOUT]: (state) => {
    state.authToken = '';
    state.authStatus.type = '';
    state.authStatus.error = '';
  },
};

export const state = {
  authToken: localStorage.getItem('authToken') || '',
  authStatus: {
    type: '',
    error: '',
  },
  signupStatus: {
    type: '',
    error: '',
  },
};
