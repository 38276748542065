import axios from 'axios';
import {
  GET_PLACES,
  GET_PLACES_SUCCESS,
  GET_PLACES_ERROR,
  GET_PLACE,
  GET_PLACE_SUCCESS,
  GET_PLACE_ERROR,
  ADD_PLACE,
  ADD_PLACE_SUCCESS,
  ADD_PLACE_ERROR,
  EDIT_PLACE,
  EDIT_PLACE_SUCCESS,
  EDIT_PLACE_ERROR,
  DELETE_PLACE,
  DELETE_PLACE_SUCCESS,
  DELETE_PLACE_ERROR,
  CLEAR_PLACE_STATE,
} from './types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getAllPlaces = async ({ commit }) => {
  commit(GET_PLACES);
  try {
    const { data } = await axios.get(`${apiBaseURL}/places`);
    commit(GET_PLACES_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(GET_PLACES_ERROR, e);
    return Promise.reject(e);
  }
};

const getPlace = async ({ commit }, id) => {
  commit(GET_PLACE);
  try {
    const { data } = await axios.get(`${apiBaseURL}/places/${id}`);
    commit(GET_PLACE_SUCCESS, data);
  } catch (e) {
    commit(GET_PLACE_ERROR, e);
  }
};

const addPlace = async ({ commit }, place) => {
  commit(ADD_PLACE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/places`, place);
    commit(ADD_PLACE_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(ADD_PLACE_ERROR, e);
    return Promise.reject(e);
  }
};

const editPlace = async ({ commit }, place) => {
  commit(EDIT_PLACE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/places/${place.id}`, {
      ...place,
      _method: 'PUT',
    });
    commit(EDIT_PLACE_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(EDIT_PLACE_ERROR, e);
    return Promise.reject(e);
  }
};

const deletePlace = async ({ commit }, id) => {
  commit(DELETE_PLACE);
  try {
    const { data } = await axios.post(`${apiBaseURL}/places/${id}`, { _method: 'DELETE' });
    commit(DELETE_PLACE_SUCCESS, data);
  } catch (e) {
    commit(DELETE_PLACE_ERROR, e);
  }
};

const clearPlaceState = async ({ commit }) => {
  commit(CLEAR_PLACE_STATE);
};

export default {
  [GET_PLACES]: getAllPlaces,
  [GET_PLACE]: getPlace,
  [ADD_PLACE]: addPlace,
  [EDIT_PLACE]: editPlace,
  [DELETE_PLACE]: deletePlace,
  [CLEAR_PLACE_STATE]: clearPlaceState,
};
