export const GET_OWNERS = 'GET_OWNERS';
export const GET_OWNERS_SUCCESS = 'GET_OWNERS_SUCCESS';
export const GET_OWNERS_ERROR = 'GET_OWNERS_ERROR';

export const GET_OWNER = 'GET_OWNER';
export const GET_OWNER_SUCCESS = 'GET_OWNER_SUCCESS';
export const GET_OWNER_ERROR = 'GET_OWNER_ERROR';

export const ADD_OWNER = 'ADD_OWNER';
export const ADD_OWNER_SUCCESS = 'ADD_OWNER_SUCCESS';
export const ADD_OWNER_ERROR = 'ADD_OWNER_ERROR';

export const EDIT_OWNER = 'EDIT_OWNER';
export const EDIT_OWNER_SUCCESS = 'EDIT_OWNER_SUCCESS';
export const EDIT_OWNER_ERROR = 'EDIT_OWNER_ERROR';

export const DELETE_OWNER = 'DELETE_OWNER';
export const DELETE_OWNER_SUCCESS = 'DELETE_OWNER_SUCCESS';
export const DELETE_OWNER_ERROR = 'DELETE_OWNER_ERROR';

export const CLEAR_OWNER_STATE = 'CLEAR_OWNER_STATE';
