<template>
  <v-overlay :value="visible" color="white" opacity="1">
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-overlay>
</template>

<script>
export default {
  props: ['visible'],
}
</script>