export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARDS_SUCCESS = 'GET_BOARDS_SUCCESS';
export const GET_BOARDS_ERROR = 'GET_BOARDS_ERROR';

export const GET_BOARD = 'GET_BOARD';
export const GET_BOARD_SUCCESS = 'GET_BOARD_SUCCESS';
export const GET_BOARD_ERROR = 'GET_BOARD_ERROR';

export const ADD_BOARD = 'ADD_BOARD';
export const ADD_BOARD_SUCCESS = 'ADD_BOARD_SUCCESS';
export const ADD_BOARD_ERROR = 'ADD_BOARD_ERROR';

export const EDIT_BOARD = 'EDIT_BOARD';
export const EDIT_BOARD_SUCCESS = 'EDIT_BOARD_SUCCESS';
export const EDIT_BOARD_ERROR = 'EDIT_BOARD_ERROR';

export const DELETE_BOARD = 'DELETE_BOARD';
export const DELETE_BOARD_SUCCESS = 'DELETE_BOARD_SUCCESS';
export const DELETE_BOARD_ERROR = 'DELETE_BOARD_ERROR';

export const CLEAR_BOARD_STATE = 'CLEAR_BOARD_STATE';
