import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth/index';
import boards from './boards/index';
import places from './places/index';
import owners from './owners/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    boards,
    places,
    owners,
  },
});
