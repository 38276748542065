import axios from 'axios';
import {
  GET_OWNERS,
  GET_OWNERS_SUCCESS,
  GET_OWNERS_ERROR,
  GET_OWNER,
  GET_OWNER_SUCCESS,
  GET_OWNER_ERROR,
  ADD_OWNER,
  ADD_OWNER_SUCCESS,
  ADD_OWNER_ERROR,
  EDIT_OWNER,
  EDIT_OWNER_SUCCESS,
  EDIT_OWNER_ERROR,
  DELETE_OWNER,
  DELETE_OWNER_SUCCESS,
  DELETE_OWNER_ERROR,
  CLEAR_OWNER_STATE,
} from './types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getAllOwners = async ({ commit }) => {
  commit(GET_OWNERS);
  try {
    const { data } = await axios.get(`${apiBaseURL}/operators`);
    commit(GET_OWNERS_SUCCESS, data);
  } catch (e) {
    commit(GET_OWNERS_ERROR, e);
  }
};

const getOwner = async ({ commit }, id) => {
  commit(GET_OWNER);
  try {
    const { data } = await axios.get(`${apiBaseURL}/operators/${id}`);
    commit(GET_OWNER_SUCCESS, data);
  } catch (e) {
    commit(GET_OWNER_ERROR, e);
  }
};

const addOwner = async ({ commit }, owner) => {
  commit(ADD_OWNER);
  try {
    const { data } = await axios.post(`${apiBaseURL}/operators`, owner);
    commit(ADD_OWNER_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(ADD_OWNER_ERROR, e);
    return Promise.reject(e);
  }
};

const editOwner = async ({ commit }, owner) => {
  commit(EDIT_OWNER);
  try {
    const { data } = await axios.post(`${apiBaseURL}/operators/${owner.id}`, {
      ...owner,
      _method: 'PUT',
    });
    commit(EDIT_OWNER_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(EDIT_OWNER_ERROR, e);
    return Promise.reject(e);
  }
};

const deleteOwner = async ({ commit }, id) => {
  commit(DELETE_OWNER);
  try {
    const { data } = await axios.post(`${apiBaseURL}/operators/${id}`, { _method: 'DELETE' });
    commit(DELETE_OWNER_SUCCESS, data);
  } catch (e) {
    commit(DELETE_OWNER_ERROR, e);
  }
};

const clearOwnerState = async ({ commit }) => {
  commit(CLEAR_OWNER_STATE);
};

export default {
  [GET_OWNERS]: getAllOwners,
  [GET_OWNER]: getOwner,
  [ADD_OWNER]: addOwner,
  [EDIT_OWNER]: editOwner,
  [DELETE_OWNER]: deleteOwner,
  [CLEAR_OWNER_STATE]: clearOwnerState,
};
