import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '@/views/Home.vue';
import Signin from '@/views/Signin.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/board/:id',
    name: 'Board',
    component: () => import(/* webpackChunkName: "board" */ '../views/Board.vue'),
    props: true,
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/places',
    name: 'Places',
    component: () => import(/* webpackChunkName: "places" */ '../views/Places.vue'),
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/owner/:id',
    name: 'Owner',
    component: () => import(/* webpackChunkName: "owner" */ '../views/Owner.vue'),
    props: true,
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/owners',
    name: 'Owners',
    component: () => import(/* webpackChunkName: "owners" */ '../views/Owners.vue'),
    meta: {
      authOnly: true,
    },
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly) && !store.getters.isAuthenticated) {
    next({ name: 'Signin' });
  } else {
    next();
  }
});

export default router;
