import {
  GET_PLACES,
  GET_PLACES_SUCCESS,
  GET_PLACES_ERROR,
  GET_PLACE,
  GET_PLACE_SUCCESS,
  GET_PLACE_ERROR,
  ADD_PLACE,
  ADD_PLACE_SUCCESS,
  ADD_PLACE_ERROR,
  EDIT_PLACE,
  EDIT_PLACE_SUCCESS,
  EDIT_PLACE_ERROR,
  DELETE_PLACE,
  DELETE_PLACE_SUCCESS,
  DELETE_PLACE_ERROR,
  CLEAR_PLACE_STATE,
} from './types';

export const getters = {
  places: (state) => state.places,
  placesStatus: (state) => state.placesStatus,
  place: (state) => state.place,
  placeStatus: (state) => state.placeStatus,
  addPlaceStatus: (state) => state.addPlaceStatus,
  editPlaceStatus: (state) => state.editPlaceStatus,
  deletePlaceStatus: (state) => state.deletePlaceStatus,
};

export const mutations = {
  [GET_PLACES]: (state) => {
    state.placesStatus = 'loading';
  },
  [GET_PLACES_SUCCESS]: (state, payload) => {
    state.places = payload.data;
    state.placesStatus = 'success';
  },
  [GET_PLACES_ERROR]: (state, e) => {
    state.placesStatus = e;
  },
  [GET_PLACE]: (state) => {
    state.placeStatus = 'loading';
  },
  [GET_PLACE_SUCCESS]: (state, payload) => {
    state.place = payload.data;
    state.placeStatus = 'success';
  },
  [GET_PLACE_ERROR]: (state, e) => {
    state.placeStatus = e;
  },
  [ADD_PLACE]: (state) => {
    state.addPlaceStatus = 'loading';
  },
  [ADD_PLACE_SUCCESS]: (state) => {
    state.addPlaceStatus = 'success';
  },
  [ADD_PLACE_ERROR]: (state, e) => {
    state.addPlaceStatus = e;
  },
  [EDIT_PLACE]: (state) => {
    state.editPlaceStatus = 'loading';
  },
  [EDIT_PLACE_SUCCESS]: (state) => {
    state.editPlaceStatus = 'success';
  },
  [EDIT_PLACE_ERROR]: (state, e) => {
    state.editPlaceStatus = e;
  },
  [DELETE_PLACE]: (state) => {
    state.deletePlaceStatus = 'loading';
  },
  [DELETE_PLACE_SUCCESS]: (state) => {
    state.deletePlaceStatus = 'success';
  },
  [DELETE_PLACE_ERROR]: (state, e) => {
    state.deletePlaceStatus = e;
  },
  [CLEAR_PLACE_STATE]: (state) => {
    state.place = {};
    state.placeStatus = '';
    state.addPlaceStatus = '';
    state.editPlaceStatus = '';
    state.deletePlaceStatus = '';
  },
};

export const state = {
  places: [],
  placesStatus: '',
  place: {},
  placeStatus: '',
  addPlaceStatus: '',
  editPlaceStatus: '',
  deletePlaceStatus: '',
};
