<template>
	<v-container>
		<v-btn v-if="isAuthenticated" color="primary" dark elevation="2" fab fixed bottom right :to="{ name: 'Board', params: { id: 'new' } }">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-row class="text-center">
			<v-col cols="12" class="billboardsFilters mt-3 mb-3">
				<v-row no-gutters>
					<v-col lg="4" sm="12" cols="12" class="mb-lg-0 mb-5">
						<v-combobox :items="places" v-model="selectedPlaces" label="Населений пункт"
												clearable multiple outlined small-chips deletable-chips hide-details class="mr-lg-2" />
					</v-col>
					<v-col lg="4" sm="12" cols="12" class="mb-lg-0 mb-5">
						<v-combobox :items="owners" v-model="selectedOwners" label="Оператор" autocomplete="autocomplete_off_ergergt" name="owner"
												clearable multiple outlined small-chips deletable-chips hide-details class="mr-lg-2" />
					</v-col>
					<v-col lg="4" sm="12" cols="12">
						<v-combobox :items="statuses" v-model="selectedStatus" label="Статус"
												clearable outlined hide-details />
					</v-col>
					<!-- <v-col lg="2" sm="8" cols="12" class="mb-sm-0 mb-5">
						<v-combobox :items="statuses" v-model="selectedStatus" label="Статус"
												clearable outlined hide-details class="mr-sm-2" />
					</v-col>
					<v-col lg="2" sm="4" cols="12">
						<v-checkbox v-model="onlyAvailable" label="Только свободные"
												hide-details class="mt-0 pt-sm-0" />
					</v-col> -->
				</v-row>
			</v-col>
			<v-col cols="12" ref="map">
				<v-sheet rounded class="map">
					<GmapMap
						:center="mapCenter"
						:zoom="mapZoom"
						style="width: 100%; height: 100%"
						@center_changed="updateMapCenter"
						@dragend="syncMapCenter"
						@zoom_changed="syncMapZoom"
					>
						<GmapCluster :styles="clasterStyles" :maxZoom="14">
							<GmapMarker
								:key="billboard.id"
								v-for="billboard in filteredBillboards"
								:position="billboard.location"
								clickable
								:icon="markerIcon(billboard.status)"
								@click="showModalInfo(billboard)"
							/>
						</GmapCluster>
					</GmapMap>
				</v-sheet>
			</v-col>
			<v-col cols="12">
				<v-sheet outlined rounded class="billboards_wrapper">
					<v-data-table :headers="tableHeaders" :items="filteredBillboards"
												hide-default-footer disable-filtering multi-sort
												@click:row="showItemOnMap" class="billboards"
												no-data-text="На жаль, нічого нема." :items-per-page="-1">
						<template v-slot:item.status="{ item }">
							<span :style="{ 'background-color': getColorByStatus(item.status) }" class="billboardsStatusMarker"></span>
							<span class="billboardsStatus" :style="{ 'color': getColorByStatus(item.status) }">
								{{ getStatusTitle(item.status) }}
							</span>
						</template>
						<template v-slot:item.location="{ item }">
							<a :href="generateGoogleMapsLink(item.location)" target="_blank" @click.stop>
								{{ item.location.lat }}, {{ item.location.lng }}
							</a>
						</template>
						<template v-slot:item.phone="{ item }">
							<a :href="`tel:${item.phone}`" @click.stop>
								{{ item.phone }}
							</a>
							<br v-if="item.email">
							<a :href="`mailto:${item.email}`" v-if="item.email" @click.stop>
								{{ item.email }}
							</a>
						</template>
						<template v-slot:item.isAvailable="{ item }">
							{{ item.isAvailable ? 'Вільний' : 'Зайнятий' }}
						</template>

						<template v-slot:item.actions="{ item }">
							<v-btn small icon :to="{ name: 'Board', params: { id: item.id} }" @click.stop class="mr-2">
								<v-icon>mdi-pencil</v-icon>
							</v-btn>
							<v-btn small icon @click.stop="deleteBoardConfirm(item)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-sheet>
			</v-col>
			<v-dialog v-model="isModalInfoVisible" width="500" :open-delay="5000" v-if="selectedBillboard">
				<v-card class="billboardInfo pb-5">
					<v-img 	aspect-ratio="1.7778" min-height="200" v-if="selectedBillboard.images.length === 1"
									:src="`api${selectedBillboard.images[0].image}`" />
					<v-carousel v-model="imagesDefaultSlide" hide-delimiters height="auto" v-if="selectedBillboard.images.length > 1" class="billboardSlider">
						<v-carousel-item eager v-for="image in selectedBillboard.images" :key="image.image">
							<v-img aspect-ratio="1.7778" min-height="200" :src="`api${image.image}`" />
							<span class="billboardSliderTitle">{{ image.title }}</span>
						</v-carousel-item>
					</v-carousel>

					<div class="billboardInfoActions">
						<div class="billboardInfoActionsBtnWrapper">
							<v-btn icon dark @click="isModalInfoVisible = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
						<div class="billboardInfoActionsBtnWrapper" v-if="isAuthenticated">
							<v-btn icon dark @click="deleteBoardConfirm(selectedBillboard)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</div>
						<div class="billboardInfoActionsBtnWrapper" v-if="isAuthenticated">
							<v-btn icon dark :to="{ name: 'Board', params: { id: selectedBillboard.id} }">
								<v-icon>mdi-pencil</v-icon>
							</v-btn>
						</div>
					</div>

					<v-card-title>
						{{ selectedBillboard.place }}
						<span class="font-italic text-lowercase text-body-2 ml-1" :style="{ 'color': getColorByStatus(selectedBillboard.status) }">
							({{ getStatusTitle(selectedBillboard.status) }})
						</span>
					</v-card-title>

					<v-card-text class="d-flex align-center">
						<v-icon left>mdi-account-circle-outline</v-icon>
						{{ selectedBillboard.owner }}
					</v-card-text>
					<v-card-text class="d-flex align-center">
						<v-icon left>mdi-phone-outline</v-icon>
						<a :href="`tel:${selectedBillboard.phone}`">{{ selectedBillboard.phone }}</a>
					</v-card-text>
					<v-card-text class="d-flex align-center">
						<v-icon left>mdi-map-marker-outline</v-icon>
						<a :href="generateGoogleMapsLink(selectedBillboard.location)" target="_blank">{{ selectedBillboard.location.lat }}, {{ selectedBillboard.location.lng }}</a>
					</v-card-text>
					<!-- <v-card-text class="d-flex align-center">
						<v-icon left>mdi-calendar-clock-outline</v-icon>
						{{ selectedBillboard.isAvailable ? 'Свободен' : 'Занят' }}
					</v-card-text> -->
				</v-card>
			</v-dialog>
			<v-dialog v-model="isConfirmDeleteBoardVisible" max-width="500px">
				<v-card :loading="isBoardBeingDeleted">
					<v-card-title class="pt-5 pb-5">Впевнені, що хочете видалити цей борд?</v-card-title>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="grey" text @click="closeDeleteBoard">Відмінити</v-btn>
						<v-btn color="red" text @click="deleteBoard">Видалити</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<AppPageLoader :visible="isPageLoading" />
		</v-row>
	</v-container>
</template>

<script>
import { GET_BOARDS, DELETE_BOARD } from '@/store/boards/types';
import { GET_PLACES } from '@/store/places/types';
import { GET_OWNERS } from '@/store/owners/types';
import clusterIcon from '@/assets/images/cluster.svg';

export default {
	name: 'Home',

	data: () => ({
		isPageLoading: false,
		selectedBillboard: '',
		isModalInfoVisible: false,
		isConfirmDeleteBoardVisible: false,
		boardToDelete: '',
		isBoardBeingDeleted: false,
		mapZoom: 12,
		clasterStyles: [
			{
				width: 50,
				height: 50,
				url: clusterIcon,
				textColor: "#fff",
				textSize: 10,
			},
		],
		mapCenter: {
			lat: 50.39044,
			lng: 30.23280,
		},
		reportedMapCenter: {},
		selectedPlaces: [],
		selectedOwners: [],
		statuses: [
			{
				text: 'Схвалено',
				value: 'approved',
			},
			{
				text: 'Не схвалено',
				value: 'rejected',
			},
			{
				text: 'Обробляється',
				value: 'processing',
			},
		],
		selectedStatus: '',
		onlyAvailable: false,
		imagesDefaultSlide: 0,
	}),

	methods: {
		async init() {
			this.isPageLoading = true;

			await Promise.all([
				this.getBillboards(),
				this.getPlaces(),
				this.getOwners(),
			]);

			setTimeout(() => {
        this.isPageLoading = false;
      }, 500);
		},
		async getBillboards() {
			await this.$store.dispatch(GET_BOARDS);
		},
		async getPlaces() {
      await this.$store.dispatch(GET_PLACES);
		},
		async getOwners() {
      await this.$store.dispatch(GET_OWNERS);
		},
		async deleteBoard() {
			this.isBoardBeingDeleted = true;
			await this.$store.dispatch(DELETE_BOARD, this.boardToDelete.id);
			await this.getBillboards();
			this.closeDeleteBoard();
			this.isModalInfoVisible = false;
		},
		closeDeleteBoard() {
			this.isBoardBeingDeleted = false;
			this.boardToDelete = null;
			this.isConfirmDeleteBoardVisible = false;
		},
		deleteBoardConfirm(board) {
			this.boardToDelete = board;
			this.isConfirmDeleteBoardVisible = true;
		},
		showModalInfo(billboard) {
			this.selectedBillboard = billboard;
			this.imagesDefaultSlide = 0;
			this.isModalInfoVisible = true;
		},
		showItemOnMap($event) {
			this.reportedMapCenter = $event.location;
			this.mapZoom = 18;
			this.syncMapCenter();
			// window.scrollTo({
			// 	top: 100,
			// 	left: 0,
			// 	behavior: 'smooth',
			// });
			this.$refs.map.scrollIntoView({
				behavior: 'smooth',
			});
		},
		markerIcon(status) {
			return {
				path: "M25 12.5C25 5.57598 19.424 0 12.5 0C5.57598 0 0 5.57598 0 12.5C0 19.424 12.5 32.3529 12.5 32.3529C12.5 32.3529 25 19.424 25 12.5ZM6.67892 12.2549C6.67892 9.06863 9.31372 6.43382 12.5 6.43382C15.6863 6.43382 18.3211 9.00735 18.3211 12.2549C18.3211 15.4412 15.7475 18.076 12.5 18.076C9.31372 18.076 6.67892 15.4412 6.67892 12.2549Z",
				fillColor: this.getColorByStatus(status),
				fillOpacity: 1,
				strokeWeight: 1,
				strokeColor : '#fff',
				rotation: 0,
				anchor: {
					x: 12,
					y: 32,
				},
			};
		},
		getColorByStatus(status) {
			const colorTheme = this.$vuetify.theme.defaults.dark;
			let color = '';
			switch(status) {
				case 'approved':
					color = colorTheme.success;
				break;

				case 'rejected':
					color = colorTheme.error;
				break;

				case 'processing':
					color = colorTheme.warning;
				break;

				default:
					color = colorTheme.warning;
				break;
			}
			return color;
		},
		getStatusTitle(status) {
			let title = '';
			switch(status) {
				case 'approved':
					title = 'Схвалено';
				break;

				case 'rejected':
					title = 'Не схвалено';
				break;

				case 'processing':
					title = 'Обробляється';
				break;

				default:
					title = 'Обробляється';
				break;
			}
			return title;
		},
		generateGoogleMapsLink(location) {
			return `http://www.google.com/maps/place/${location.lat},${location.lng}`;
		},
		updateMapCenter(coordinates) {
			this.reportedMapCenter = {
				lat: coordinates.lat(),
				lng: coordinates.lng(),
			}
		},
		syncMapCenter() {
			this.mapCenter = this.reportedMapCenter;
		},
		syncMapZoom(e) {
			this.mapZoom = e;
		},
	},
	computed: {
		billboards() {
			return this.$store.getters.boards;
		},
		filteredBillboards() {
			return this.billboards.filter(billboard => {
				let placeFilter = true;
				if (this.selectedPlaces.length) {
					placeFilter = this.selectedPlaces.includes(billboard.place);
				}

				let ownerFilter = true;
				if (this.selectedOwners.length) {
					ownerFilter = this.selectedOwners.includes(billboard.owner);
				}

				let statusFilter = true;
				if (this.selectedStatus) {
					statusFilter = this.selectedStatus.value === billboard.status;
				}

				let availabilityFilter = true;
				if (this.onlyAvailable) {
					availabilityFilter = billboard.isAvailable;
				}

				return 	placeFilter && ownerFilter && statusFilter && availabilityFilter;
			});
		},
		placesData() {
      return this.$store.getters.places;
    },
		places() {
			return this.placesData.map(place => place.name);
		},
		ownersData() {
      return this.$store.getters.owners;
    },
		owners() {
			return this.ownersData.map(owner => owner.name);
		},
		isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
		tableHeaders() {
			const headers = [
				{
					text: 'Статус',
					value: 'status',
					sortable: false,
				},
				{ 
					text: 'Населений пункт',
					value: 'place',
				},
				{ 
					text: 'Координати',
					value: 'location',
					sortable: false,
				},
				{ 
					text: 'Оператор',
					value: 'owner',
				},
				{ 
					text: 'Контакти',
					value: 'phone',
					sortable: false,
				},
				// { 
				// 	text: 'Свободность',
				// 	value: 'isAvailable',
				// },
			];

			if (this.isAuthenticated) {
				headers.push({ 
					text: 'Дії',
					value: 'actions',
					sortable: false,
					width: 100,
				});
			}

			return headers;
		},
	},
	created() {
		this.init();
	},
}
</script>

<style lang="scss" scoped>
.map {
	position: relative;
	padding-bottom: 56.25%;
	min-height: 300px;
	overflow: hidden;

	.vue-map-container {
		position: absolute;
	}
	::v-deep .cluster {
		display: flex;
    align-items: center;
    justify-content: center;
	}
}
.billboards_wrapper {
	margin-bottom: 72px;

	::v-deep {
		& {}
		thead:not(.v-data-table-header-mobile) th:first-child {
			width: 0;
			padding: 0;
			font-size: 0;
		}
		tr:not(.v-data-table__empty-wrapper) td:first-child:not(.v-data-table__mobile-row) {
			width: 0;
			padding: 0;
			font-size: 0;

			.billboardsStatusMarker {
				height: 100%;
				width: 8px;
				display: block;
			}
		}
		.v-data-table__mobile-table-row {
			position: relative;

			.billboardsStatusMarker {
				position: absolute;
				height: 100%;
				width: 4px;
				top: 0;
				left: 0;
			}
		}
	}
}
.billboardsFilters {
	::v-deep {
		& {}
		.v-input {
			height: 100%;
		}
		.v-input__control {
			height: 100%;
		}
		.v-input__slot {
			height: 100%;
		}
		.v-input__append-inner {
			align-self: center;
			margin-top: 0;
		}
	}
}
.billboardSliderTitle {
	background: linear-gradient(0, rgba(0, 0, 0, .5), transparent);
	color: #fff;
	text-align: center;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	padding-bottom: 8px;
	font-size: 14px;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}
.billboardInfoActions {
	position: absolute;
	margin: 16px;
	top: 0;
	right: 0;
	z-index: 1;
	display: grid;
	gap: 4px;

	.billboardSlider + & {
		direction: rtl;
		grid-template-columns: 1fr 1fr 1fr;
	}
}
.billboardInfoActionsBtnWrapper {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
</style>
