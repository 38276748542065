import axios from 'axios';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vuelidate';
import './plugins/gmap-vue';
import AppPageLoader from './components/PageLoader.vue';
import { LOGOUT } from './store/auth/types';

Vue.config.productionTip = false;
Vue.component('AppPageLoader', AppPageLoader);

const token = localStorage.getItem('authToken');
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch(LOGOUT);
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
