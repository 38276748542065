import axios from 'axios';
import {
  GET_BOARDS,
  GET_BOARDS_SUCCESS,
  GET_BOARDS_ERROR,
  GET_BOARD,
  GET_BOARD_SUCCESS,
  GET_BOARD_ERROR,
  ADD_BOARD,
  ADD_BOARD_SUCCESS,
  ADD_BOARD_ERROR,
  EDIT_BOARD,
  EDIT_BOARD_SUCCESS,
  EDIT_BOARD_ERROR,
  DELETE_BOARD,
  DELETE_BOARD_SUCCESS,
  DELETE_BOARD_ERROR,
  CLEAR_BOARD_STATE,
} from './types';

const apiBaseURL = process.env.VUE_APP_API_URL;

const getAllBoards = async ({ commit }) => {
  commit(GET_BOARDS);
  try {
    const { data } = await axios.get(`${apiBaseURL}/boards`);
    commit(GET_BOARDS_SUCCESS, data);
  } catch (e) {
    commit(GET_BOARDS_ERROR, e);
  }
};

const getBoard = async ({ commit }, id) => {
  commit(GET_BOARD);
  try {
    const { data } = await axios.get(`${apiBaseURL}/boards/${id}`);
    commit(GET_BOARD_SUCCESS, data);
  } catch (e) {
    commit(GET_BOARD_ERROR, e);
  }
};

const addBoard = async ({ commit }, board) => {
  commit(ADD_BOARD);
  try {
    const { data } = await axios.post(`${apiBaseURL}/boards`, board);
    commit(ADD_BOARD_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(ADD_BOARD_ERROR, e);
    return Promise.reject(e);
  }
};

const editBoard = async ({ commit }, board) => {
  commit(EDIT_BOARD);
  try {
    const { data } = await axios.post(`${apiBaseURL}/boards/${board.id}`, board.formData);
    commit(EDIT_BOARD_SUCCESS, data);
    return Promise.resolve();
  } catch (e) {
    commit(EDIT_BOARD_ERROR, e);
    return Promise.reject(e);
  }
};

const deleteBoard = async ({ commit }, id) => {
  commit(DELETE_BOARD);
  try {
    const { data } = await axios.post(`${apiBaseURL}/boards/${id}`, { _method: 'DELETE' });
    commit(DELETE_BOARD_SUCCESS, data);
  } catch (e) {
    commit(DELETE_BOARD_ERROR, e);
  }
};

const clearBoardState = async ({ commit }) => {
  commit(CLEAR_BOARD_STATE);
};

export default {
  [GET_BOARDS]: getAllBoards,
  [GET_BOARD]: getBoard,
  [ADD_BOARD]: addBoard,
  [EDIT_BOARD]: editBoard,
  [DELETE_BOARD]: deleteBoard,
  [CLEAR_BOARD_STATE]: clearBoardState,
};
