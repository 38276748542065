import {
  GET_OWNERS,
  GET_OWNERS_SUCCESS,
  GET_OWNERS_ERROR,
  GET_OWNER,
  GET_OWNER_SUCCESS,
  GET_OWNER_ERROR,
  ADD_OWNER,
  ADD_OWNER_SUCCESS,
  ADD_OWNER_ERROR,
  EDIT_OWNER,
  EDIT_OWNER_SUCCESS,
  EDIT_OWNER_ERROR,
  DELETE_OWNER,
  DELETE_OWNER_SUCCESS,
  DELETE_OWNER_ERROR,
  CLEAR_OWNER_STATE,
} from './types';

export const getters = {
  owners: (state) => state.owners,
  ownersStatus: (state) => state.ownersStatus,
  owner: (state) => state.owner,
  ownerStatus: (state) => state.ownerStatus,
  addOwnerStatus: (state) => state.addOwnerStatus,
  editOwnerStatus: (state) => state.editOwnerStatus,
  deleteOwnerStatus: (state) => state.deleteOwnerStatus,
};

export const mutations = {
  [GET_OWNERS]: (state) => {
    state.ownersStatus = 'loading';
  },
  [GET_OWNERS_SUCCESS]: (state, payload) => {
    state.owners = payload.data;
    state.ownersStatus = 'success';
  },
  [GET_OWNERS_ERROR]: (state, e) => {
    state.ownersStatus = e;
  },
  [GET_OWNER]: (state) => {
    state.ownerStatus = 'loading';
  },
  [GET_OWNER_SUCCESS]: (state, payload) => {
    state.owner = payload.data;
    state.ownerStatus = 'success';
  },
  [GET_OWNER_ERROR]: (state, e) => {
    state.ownerStatus = e;
  },
  [ADD_OWNER]: (state) => {
    state.addOwnerStatus = 'loading';
  },
  [ADD_OWNER_SUCCESS]: (state) => {
    state.addOwnerStatus = 'success';
  },
  [ADD_OWNER_ERROR]: (state, e) => {
    state.addOwnerStatus = e;
  },
  [EDIT_OWNER]: (state) => {
    state.editOwnerStatus = 'loading';
  },
  [EDIT_OWNER_SUCCESS]: (state) => {
    state.editOwnerStatus = 'success';
  },
  [EDIT_OWNER_ERROR]: (state, e) => {
    state.editOwnerStatus = e;
  },
  [DELETE_OWNER]: (state) => {
    state.deleteOwnerStatus = 'loading';
  },
  [DELETE_OWNER_SUCCESS]: (state) => {
    state.deleteOwnerStatus = 'success';
  },
  [DELETE_OWNER_ERROR]: (state, e) => {
    state.deleteOwnerStatus = e;
  },
  [CLEAR_OWNER_STATE]: (state) => {
    state.owner = {};
    state.ownerStatus = '';
    state.addOwnerStatus = '';
    state.editOwnerStatus = '';
    state.deleteOwnerStatus = '';
  },
};

export const state = {
  owners: [],
  ownersStatus: '',
  owner: {},
  ownerStatus: '',
  addOwnerStatus: '',
  editOwnerStatus: '',
  deleteOwnerStatus: '',
};
