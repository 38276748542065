<template>
  <v-app>
    <v-app-bar class="flex-grow-0" color="primary" dark flat v-if="$route.name !== 'Signin'">
      <div class="d-flex align-center app_nav flex-grow-1">
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <!-- <v-toolbar-title>
          <router-link class="white--text text-decoration-none" :to="{ name: 'Home' }">Билборды</router-link>
        </v-toolbar-title> -->
        <span v-if="!isAuthenticated">
          Билборды
        </span>
        <router-link v-if="isAuthenticated" class="mr-5 white--text text-decoration-none" :to="{ name: 'Home' }" :class="{'is-active': $route.name === 'Board'}">
          Білборди
        </router-link>
        <router-link  v-if="isAuthenticated" class="mr-5 white--text text-decoration-none" :to="{ name: 'Places' }">
          Населені пункти
        </router-link>
        <router-link  v-if="isAuthenticated" class="white--text text-decoration-none" :to="{ name: 'Owners' }" :class="{'is-active': $route.name === 'Owner'}">
          Оператори
        </router-link>
        <v-spacer></v-spacer>
        <v-btn  v-if="isAuthenticated" icon @click="logout">
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { LOGOUT } from '@/store/auth/types';

export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
    },
  },
};
</script>

<style lang="scss">
.app_nav {
  height: 100%;

  a {
    opacity: .5;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: currentColor;
      top: -4px;
      left: 0;
      opacity: 0;
    }
    &.is-active,
    &.router-link-exact-active {
      opacity: 1;

      &::before {
        opacity: 1;
      }
    }
  }
}
.v-input.is-required .v-label::after {
  content: " *";
  color: var(--v-error-base);
}
</style>
