<template>
	<v-container class="signin pb-16" fill-height>
		<v-row class="m-auto">
			<v-col cols="12" class="mt-3 mb-3">
        <h2 class="text-h4">Авторизація</h2>
      </v-col>
      <v-col cols="12">
        <v-form @submit.prevent="submit">
          <v-row no-gutters>
            <v-col cols="12" class="mb-5">
              <v-text-field v-model="username" label="Ім'я" outlined hide-details="auto"
                            @input="$v.username.$touch()" @blur="$v.username.$touch()"
                            :error-messages="usernameErrors" class="is-required" />
            </v-col>
            <v-col cols="12" class="mb-5">
              <v-text-field v-model="password" label="Пароль" outlined hide-details="auto"
                            @input="$v.password.$touch()" @blur="$v.password.$touch()" type="password"
                            :error-messages="passwordErrors" class="is-required" />
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" type="submit" dark depressed x-large :loading="isBeingSubmitted">Увійти</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { LOGIN_REQUEST } from '@/store/auth/types';

export default {
  data: () => ({
    username: '',
    password: '',
    isBeingSubmitted: false,
    snack: false,
    snackColor: '',
    snackText: '',
  }),
  props: ['id'],
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      this.isBeingSubmitted = true;

      try {
        await this.$store.dispatch(LOGIN_REQUEST, {
          name: this.username,
          password: this.password,
        });
        this.$router.push({ name: 'Home'});
      } catch (e) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Упс, щось пішло не так!';
      }
      
      this.isBeingSubmitted = false;
    }
  },
  computed: {
    usernameErrors () {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push('Ім\'я обов\'язково.');
      return errors;
    },
    passwordErrors () {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('Пароль обов\'язковий.');
      return errors;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  validations: {
    username: { 
      required,
    },
    password: { 
      required,
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.replace({name: 'Home'});
    }
  },
}
</script>

<style lang="scss" scoped>
.signin {
  max-width: 350px;
}
[type="submit"] {
  width: 100%;
}
</style>
