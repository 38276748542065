export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const PASSWORD_RECOVERY_REQUEST = 'PASSWORD_RECOVERY_REQUEST';
export const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS';
export const PASSWORD_RECOVERY_ERROR = 'PASSWORD_RECOVERY_ERROR';

export const LOGOUT = 'LOGOUT';
